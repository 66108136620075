import { Link } from "gatsby";
import * as React from "react";

type PostLinkProps = Queries.PostListItemPropsFragment;

export const PostListItem: React.FC<PostLinkProps> = (props) => {
  return (
    <li className="pt-6 pb-4">
      <Link to={"/post/" + props.slug} key={props.slug} className="group ">
        <h2 className="mb-2 text-2xl font-bold transition-all  group-hover:underline">
          {props.title}
        </h2>
        <p className="text-gray-500">
          <time>{props.createdAt}</time>
          {"・"}
          <span>{props.content?.childMarkdownRemark?.timeToRead} min read</span>
        </p>
        <span className="text-gray-700">
          {props.content?.childMarkdownRemark?.excerpt}
        </span>
      </Link>
    </li>
  );
};
