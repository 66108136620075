import { graphql, Link, PageProps } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import * as React from "react";

import { Layout } from "@/components/Layout";
import { PostListItem } from "@/components/PostListItem";

const IndexPage: React.FC<PageProps<Queries.IndexPageQuery>> = ({ data }) => {
  return (
    <Layout>
      <GatsbySeo title="Home" />
      <div className="prose pt-12 pb-4">
        <h1 className="md:text-6xl">{data.site?.siteMetadata?.title}</h1>
        <p>{data.site?.siteMetadata?.description}</p>
      </div>
      <div className="flex items-end justify-between border-b-2 pt-12 pb-4">
        <h2 className="text-2xl font-bold">新着の投稿</h2>
        <Link to="/post">
          <span className="text-gray-500 transition-all hover:text-gray-700 hover:underline">
            More post →
          </span>
        </Link>
      </div>
      <ul className="mb-8 list-none">
        {data.allContentfulPost.nodes.map((props) => (
          <PostListItem {...props} />
        ))}
      </ul>
    </Layout>
  );
};

export const query = graphql`
  query IndexPage {
    site {
      siteMetadata {
        title
        description
      }
    }
    allContentfulPost(limit: 5) {
      nodes {
        ...PostListItemProps
      }
    }
  }

  # This fragment is neccessary to generate interface of @/components/PostListItem
  fragment PostListItemProps on ContentfulPost {
    title
    slug
    createdAt(formatString: "MMM D")
    content {
      childMarkdownRemark {
        excerpt
        timeToRead
      }
    }
  }
`;

export default IndexPage;
